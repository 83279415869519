<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <!-- skeleton  -->

            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <code>* </code><label for="branchId">สาขา:</label>
                    <multiselect
                      id="branchId"
                      v-model="form.branchId"
                      :options="localDataBranchUser"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.form.branchId.$error,
                      }"
                      track-by="nameTh"
                      label="nameTh"
                      :show-labels="false"
                    >
                    </multiselect>

                    <div
                      v-if="submitform && $v.form.branchId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.branchId.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="groupId">กลุ่มอะไหล่:</label>
                    <multiselect
                      id="groupId"
                      v-model="form.groupId"
                      :options="optionsGroupId"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.form.groupId.$error,
                      }"
                      label="nameTh"
                      track-by="nameTh"
                      :show-labels="false"
                    >
                    </multiselect>

                    <div
                      v-if="submitform && $v.form.groupId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.groupId.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="codeType"
                      ><code>* </code> ประเภทรหัสอะไหล่:</label
                    >
                    <multiselect
                      id="codeType"
                      v-model="form.codeType"
                      :options="opcodeType"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.form.codeType.$error,
                      }"
                      track-by="name"
                      label="name"
                      :show-labels="false"
                    >
                    </multiselect>

                    <div
                      v-if="submitform && $v.form.codeType.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.codeType.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="partCode"> <code>*</code> รหัสอะไหล่:</label>
                    <!-- disabled="disabled"  -->
                    <input
                      id="partCode"
                      v-model="form.partCode"
                      type="text"
                      class="form-control"
                      value="Otto"
                      :class="{
                        'is-invalid': submitform && $v.form.partCode.$error,
                      }"
                    />
                    <div
                      v-if="submitform && $v.form.partCode.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.partCode.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="nameTh">ชื่ออะไหล่ (ภาษาไทย):</label>
                    <div class="input-group">
                      <input
                        id="nameTh"
                        v-model="form.nameTh"
                        type="text"
                        class="form-control"
                        placeholder="ชื่ออะไหล่ (ภาษาไทย)"
                        :class="{
                          'is-invalid': submitform && $v.form.nameTh.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.nameTh.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.nameTh.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="nameEn">ชื่ออะไหล่ (ภาษาอังกฤษ):</label>
                    <div class="input-group">
                      <input
                        id="nameEn"
                        v-model="form.nameEn"
                        type="text"
                        class="form-control"
                        placeholder="ชื่ออะไหล่ (ภาษาอังกฤษ)"
                        :class="{
                          'is-invalid': submitform && $v.form.nameEn.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.nameEn.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.nameEn.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="year">ปี (ค.ศ.):</label>
                    <input
                      size="4"
                      id="year"
                      v-model="form.year"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitform && $v.form.year.$error,
                      }"
                    />

                    <div
                      v-if="submitform && $v.form.year.$error"
                      class="invalid-tooltip"
                    >
                      <span
                        v-if="!$v.form.year.numeric || !$v.form.year.maxLength"
                        >{{ message }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-4 col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="storage">ที่เก็บ</label>

                    <multiselect
                      id="storage"
                      v-model="form.storage"
                      :options="options"
                      class=""
                      :class="{
                        'is-invalid': submitform && $v.form.storage.$error,
                      }"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.form.storage.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.storage.required">{{
                        message
                      }}</span>
                    </div>
                  </div>
                </div> -->

                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="costPrice">ต้นทุน:</label>
                    <div class="input-group">
                      <input
                        separator=","
                        :min="0.0"
                        id="costPrice"
                        v-model="form.costPrice"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.form.costPrice.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.costPrice.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.costPrice.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="sellPrice">ราคาขาย/หน่วย (1):</label>
                    <div class="input-group">
                      <input
                        :min="0.0"
                        separator=","
                        id="sellPrice"
                        v-model="form.sellPrice"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.form.sellPrice.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.sellPrice.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.sellPrice.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="sellPrice2">ราคาขาย/หน่วย (2):</label>
                    <div class="input-group">
                      <input
                        :min="0.0"
                        separator=","
                        id="sellPrice2"
                        v-model="form.sellPrice2"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.form.sellPrice2.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.sellPrice2.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.sellPrice2.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="sellPrice3">ราคาขาย/หน่วย (3):</label>
                    <div class="input-group">
                      <input
                        :min="0.0"
                        separator=","
                        id="sellPrice3"
                        v-model="form.sellPrice3"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        aria-describedby="validationTooltipUsernamePrepend"
                        :class="{
                          'is-invalid': submitform && $v.form.sellPrice3.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.sellPrice3.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.sellPrice3.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="unitId">หน่วยซื้อ/หน่วยขาย:</label>

                    <multiselect
                      id="unitId"
                      separator=","
                      v-model="form.unitId"
                      :options="optionsUnits"
                      :class="{
                        'is-invalid': submitform && $v.form.unitId.$error,
                      }"
                      label="nameTh"
                      track-by="nameTh"
                      :show-labels="false"
                    ></multiselect>
                    <div
                      v-if="submitform && $v.form.unitId.$error"
                      class="invalid-tooltip"
                    >
                      <span v-if="!$v.form.unitId.required">{{ message }}</span>
                    </div>
                  </div>
                </div>

                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="maxDcPer">ส่วนลดสูงสุด(%):</label>
                    <div class="input-group">
                      <input
                        min="0"
                        maxv="100"
                        id="maxDcPer"
                        v-model="form.maxDcPer"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.form.maxDcPer.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.maxDcPer.$error"
                        class="invalid-tooltip"
                      >
                        <span
                          v-if="
                            !$v.form.maxDcPer.maxValue ||
                            !$v.form.maxDcPer.minValue
                          "
                          >{{ message }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="maxDcAmount">ส่วนลดสูงสุด(บาท):</label>
                    <div class="input-group">
                      <input
                        :min="1"
                        separator=","
                        id="maxDcAmount"
                        v-model="form.maxDcAmount"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        :class="{
                          'is-invalid':
                            submitform && $v.form.maxDcAmount.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.maxDcAmount.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.maxDcAmount.required">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="stockMax">Max:</label>
                    <div class="input-group">
                      <input
                        :min="1"
                        maxlength="11"
                        minlength="1"
                        id="stockMax"
                        v-model="form.stockMax"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.form.stockMax.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.stockMax.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.stockMax.maxLength">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-2 col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="stockMin">Min:</label>
                    <div class="input-group">
                      <input
                        :min="1"
                        id="stockMin"
                        v-model="form.stockMin"
                        type="text"
                        class="form-control text-end"
                        placeholder=""
                        :class="{
                          'is-invalid': submitform && $v.form.stockMin.$error,
                        }"
                      />
                      <div
                        v-if="submitform && $v.form.stockMin.$error"
                        class="invalid-tooltip"
                      >
                        <span v-if="!$v.form.stockMin.maxLength">{{
                          message
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md">
                  <b-form-checkbox
                    v-model="form.useInBp"
                    value="1"
                    unchecked-value="0"
                    class="mb-3"
                    checked
                    plain
                    >ใช้กับศูนย์บริการ</b-form-checkbox
                  >
                  <b-form-checkbox
                    v-model="form.useInGs"
                    unchecked-value="0"
                    value="1"
                    class="mb-3"
                    checked
                    plain
                    >ใช้กับศูนย์ซ่อมสีและตัวถัง</b-form-checkbox
                  >
                </div>
              </div>

              <div class="col-md">
                <button class="btn btn-success float-end" type="submit">
                  บันทึก
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>

    <!-- end col -->
  </Layout>
</template>
<script>
import {
  required,
  maxValue,
  minValue,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

/**
 * Form validation component
 */
export default {
  mounted() {},

  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  page: {
    title: appConfig.parts,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      loading: undefined,
      message: "โปรดตรวจสอบข้อมูล",
      title: "เพิ่มข้อมูลอะไหล่",
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
        {
          text: appConfig.parts,
          href: "/spare-part",
        },
        {
          text: "เพิ่มข้อมูลอะไหล่",
          active: true,
        },
      ],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      optionsYear: [],

      optionsGroupId: [],
      optionsUnits: [],
      options: [],
      use: [
        { text: "ใช้กับศูนย์ซ่อมสีและตัวถัง", value: "1" },
        { text: "ใช้กับศูนย์บริการ", value: "2" },
      ],
      opcodeType: [
        {
          name: "อะไหล่นอก",
          id: "EX",
        },
        {
          name: "กำหนดเอง",
          id: "CUSTOM",
        },
      ],
      form: {
        groupId: "",
        partCode: "",
        nameTh: "",
        nameEn: "",
        year: "",
        costPrice: "",
        sellPrice: "",
        sellPrice2: "",
        sellPrice3: "",
        unitId: "",
        maxDcPer: "",
        maxDcAmount: "",
        stockMin: "",
        stockMax: "",
        useInBp: "",
        useInGs: "",
        branchId: "",
        codeType: "",
      },

      submitform: false,
    };
  },

  validations: {
    form: {
      groupId: {},
      partCode: {
        required,
      },
      nameTh: {},
      nameEn: {},
      year: {
        numeric,
        maxLength: maxLength(4),
      },
      costPrice: {},
      sellPrice: {},
      sellPrice2: {},
      sellPrice3: {},
      unitId: {},
      maxDcPer: {
        maxValue: maxValue(100),
        minValue: minValue(0),
      },
      maxDcAmount: {},
      stockMin: {
        maxLength: maxLength(11),
      },
      stockMax: {
        maxLength: maxLength(11),
      },
      useInBp: {},
      useInGs: {},
      branchId: {
        required,
      },
      codeType: {
        required,
      },
    },
  },
  created() {
    this.getLocalData();
    this.getUnits();
    this.getGroups();
    // console.log(this.form.codeType);
  },
  methods: {
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;

      // console.log(this.localDataBranchIdUser);
    },
    formSubmit() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.form.$invalid != true) {
        this.postParts();
      }
    },
    getGroups: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/part/groups", {})
        .then((response) => {
          this.optionsGroupId = response.data.data;
          // console.log( this.optionsGroupId);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUnits: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/part/units", {})
        .then((response) => {
          this.optionsUnits = response.data.data;
          // console.log(this.optionsUnits);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    postParts: function () {
      this.overlayFlag = true;
      const bodyFormData = new FormData();

      // parse
      bodyFormData.append(
        "branchId",
        JSON.stringify(this.form.branchId.branchId)
      );
      bodyFormData.append(
        "partCode",
        this.form.partCode != null ? this.form.partCode : ""
      );
      bodyFormData.append("codeType", this.form.codeType.id);
      bodyFormData.append("groupId", this.form.groupId ? this.form.groupId.groupId:"");
      bodyFormData.append(
        "nameTh",
        this.form.nameTh != null ? this.form.nameTh : ""
      );
      bodyFormData.append(
        "nameEn",
        this.form.nameEn != null ? this.form.nameEn : ""
      );
      bodyFormData.append("year", this.form.year != null ? this.form.year : "");
      bodyFormData.append(
        "costPrice",
        this.form.costPrice != null ? this.form.costPrice : ""
      );
      bodyFormData.append(
        "sellPrice",
        this.form.sellPrice != null ? this.form.sellPrice : ""
      );
      bodyFormData.append(
        "sellPrice2",
        this.form.sellPrice2 != null ? this.form.sellPrice2 : ""
      );
      bodyFormData.append(
        "sellPrice3",
        this.form.sellPrice3 != null ? this.form.sellPrice3 : ""
      );
      bodyFormData.append(
        "unitId",
        this.form.unitId ? this.form.unitId.unitId : ""
      );
      bodyFormData.append(
        "maxDcPer",
        this.form.maxDcPer != null ? this.form.maxDcPer : ""
      );
      bodyFormData.append(
        "maxDcAmount",
        this.form.maxDcAmount != null ? this.form.maxDcAmount : ""
      );
      bodyFormData.append(
        "stockMin",
        this.form.stockMin != null ? this.form.stockMin : ""
      );
      bodyFormData.append(
        "stockMax",
        this.form.stockMax != null ? this.form.stockMax : ""
      );
      bodyFormData.append(
        "useInBp",
        this.form.useInBp != null ? this.form.useInBp : "0"
      );
      bodyFormData.append(
        "useInGs",
        this.form.useInGs != null ? this.form.useInGs : "0"
      );

      useNetw
        .post("api/part/store", bodyFormData)
        .then((response) => {
          //  console.log(response);
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "spare-part" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<style lang="scss" scoped></style>
